// https://in-the-sky.org/ephemeris.php

import React from "react";

import { Clock } from "../components/Clock";
import { Page } from "../components/Page";
import sterrenbeelden from "./sterrenbeelden.json";
import jaarwiel from "./jaarwiel.json";

const MS_PER_DAY = 1000 * 60 * 60 * 24
const MOON_MONTH = 29.530588853;
// const posMod = (x, n) => ((x % n) + n) % n;

function formatDate(d) {
  return new Intl.DateTimeFormat('nl-NL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).format(d);
}

function getJulianDate(date) {
  return date.getTime() / 86400000 - date.getTimezoneOffset() / 1440 + 2440587.5;
}

function getDays(date1, date2) {
  return Math.floor((date2 - date1) / MS_PER_DAY);
}

// http://www.ben-daglish.net/moon.shtml
function getMoonAge(today) {
  var GetFrac = function(fr) {
    return fr - Math.floor(fr);
  };
  var thisJD = getJulianDate(today);
  var year = today.getFullYear();
  var degToRad = 3.14159265 / 180;
  var K0, T, T2, T3, J0, F0, M0, M1, B1, oldJ;
  K0 = Math.floor((year - 1900) * 12.3685);
  T = (year - 1899.5) / 100;
  T2 = T * T;
  T3 = T * T * T;
  J0 = 2415020 + 29 * K0;
  F0 =
    0.0001178 * T2 -
    0.000000155 * T3 +
    (0.75933 + 0.53058868 * K0) -
    (0.000837 * T + 0.000335 * T2);
  M0 =
    360 * GetFrac(K0 * 0.08084821133) +
    359.2242 -
    0.0000333 * T2 -
    0.00000347 * T3;
  M1 =
    360 * GetFrac(K0 * 0.07171366128) +
    306.0253 +
    0.0107306 * T2 +
    0.00001236 * T3;
  B1 =
    360 * GetFrac(K0 * 0.08519585128) +
    21.2964 -
    0.0016528 * T2 -
    0.00000239 * T3;
  var phase = 0;
  var jday = 0;
  while (jday < thisJD) {
    var F = F0 + 1.530588 * phase;
    var M5 = (M0 + phase * 29.10535608) * degToRad;
    var M6 = (M1 + phase * 385.81691806) * degToRad;
    var B6 = (B1 + phase * 390.67050646) * degToRad;
    F -= 0.4068 * Math.sin(M6) + (0.1734 - 0.000393 * T) * Math.sin(M5);
    F += 0.0161 * Math.sin(2 * M6) + 0.0104 * Math.sin(2 * B6);
    F -= 0.0074 * Math.sin(M5 - M6) - 0.0051 * Math.sin(M5 + M6);
    F += 0.0021 * Math.sin(2 * M5) + 0.001 * Math.sin(2 * B6 - M6);
    F += 0.5 / 1440;
    oldJ = jday;
    jday = J0 + 28 * phase + Math.floor(F);
    phase++;
  }

  return (thisJD - oldJ) % MOON_MONTH;
}

export function Time() {
  const [date, setDate] = React.useState(new Date());

  if(!date) {
    return "Calculating";
  }
  
  function nextMoonEvent(days) {
    const day = new Date(date);
    day.setDate(day.getDate() + days);
    if(day < date) day.setDate(day.getDate() + MOON_MONTH);
    return day;
  }

  function nextSunEvent(events) {
    const year = date.getFullYear();
    var event = null;
    events.forEach(e => {
      var [month,day] = e.datum.split("-").map(s=>parseInt(s));
      day = new Date(year, month-1, day);
      if(day < date) {
	day.setYear(date.getFullYear()+1);
      }

      if(!event || (day < event.date)) {
	e.date = day;
	event = e;
      }
    })

    return { day: event.date, label: `${event.naam} (${event.omschrijving})`};
  }
  
  const moons = Array.from(new Array(8), (x, nr) => `/moon/1f31${nr + 1}.png`);
  const size = 200,
	middle = size / 2,
	moonSize = 20,
	margin = moonSize * 1.5;
  const moonDays = getMoonAge(date);
  const events = [
    {label: "nieuwe maan", day: nextMoonEvent(MOON_MONTH-moonDays)},
    {label: "volle maan", day: nextMoonEvent(MOON_MONTH * 0.5 - moonDays)},
    nextSunEvent(jaarwiel.zonnefeesten),
    nextSunEvent(jaarwiel.seizoensfeesten),
  ].sort((e1, e2) => e1.day - e2.day);
  
  return (
    <Page title="Zon, maan en sterrenbeelden">
      <p>
        Het onderstaande jaarwiel toont de{" "}
        <a href="https://tallsay.com/page/4294975342/wicca-het-jaarwiel-en-keltische-seizoensvieringen">
          Keltische feestdagen
        </a>{" "}
        en de verschillende{" "}
        <a href="https://www.sterrenbeelden.nu/">sterrenbeelden</a>. Daaromheen
        staan de fasen van de maan. De kleine wijzer toont de datum in het jaar.
        De grote wijzer toont de datum in de maancyclus.
      </p>
      <input
        type="date"
        value={formatDate(date)}
        onChange={event => {
          console.log(event.target.value);
          setDate(new Date(event.target.value));
        }}
      />
      <button
        style={{ marginLeft: "10pt" }}
        onClick={() => setDate(new Date())}
      >
        Vandaag
      </button>
      <Clock
        hours={date.getMonth() + date.getDate() / 30.5 + 0.25}
        minutes={(moonDays / MOON_MONTH) * 60}
        seconds={-1}
        size={size}
      >
        <image
          href="/jaarwiel.jpg"
          x={-middle + margin}
          y={-middle + margin}
          height={size - 2 * margin}
          width={size - 2 * margin}
        />
        {moons.map((href, nr) => (
          <image
            key={nr}
            href={href}
            x="-10"
            y={-middle + 5}
            width={moonSize}
            transform={`rotate(${nr * 45} 0 0) rotate(${nr * -45} 0 ${-middle +
              5 +
              moonSize / 2})`}
          />
        ))}
        {/* {maanden.map((maand, nr) => ( */}
        {/*   <text */}
        {/*     key={nr} */}
        {/*     x="-10" */}
        {/*     y={-middle + 30} */}
        {/*     /\* text-anchor="middle" *\/ */}
        {/*     transform={`rotate(${20 + nr * 30} 0 0)`} */}
        {/*     style={{ fontSize: "5px" }} */}
        {/*   > */}
        {/*     {maand}maand */}
        {/*   </text> */}
        {/* ))} */}
      </Clock>
      <h3>Programma</h3>
      <p>De maan is in dag {Number(moonDays).toFixed(2)} van de cyclus van {Number(MOON_MONTH).toFixed(2)} dagen ({Number(100* moonDays / MOON_MONTH).toFixed(1)}%).</p>
      <table>
	<tbody>
	  {events.map(({day, label}) =>
	    <tr key={day}>
	      <td>{formatDate(day)}</td>
	      <td>{label}</td>
	      <td>nog {getDays(date, day)} dagen</td>
	    </tr>
	  )}
	</tbody>
      </table>
    {/* <RunningClock time={date} /> */}
    {/* <Content file="jaarwiel.html" /> */}
    <h3>Sterrenbeelden</h3>
    <table>
      <thead>
        <tr style={{ background: "#eee" }}>
          <th>Astronomie</th>
          <th>Symbool</th>
          <th>Astrologie</th>
          <th>Vanaf</th>
          <th>Aanleg</th>
          <th>Geslacht</th>
          <th>Natuur</th>
          <th colSpan={4}>Hemellichaam</th>
          <th>Kruis</th>
          <th>Element</th>
        </tr>
      </thead>
      <tbody>
        {sterrenbeelden.map((sb, nr) => (
          <tr key={nr} style={{ background: nr % 2 ? "#eee" : "#fff" }}>
            <td>
              <a
                href={`https://nl.m.wikipedia.org/wiki/${sb.teken}_(sterrenbeeld)`}
                target="wiki"
              >
                {sb.naam}
              </a>
            </td>
            <td>{sb.symbool}</td>
            <td>
              <a
                href={`https://nl.m.wikipedia.org/wiki/${sb.teken}_(astrologie)`}
                target="wiki"
              >
                {sb.teken}
              </a>
            </td>
            <td>{sb.vanaf}</td>
            <td>{sb.aanleg}</td>
            <td>{sb.geslacht}</td>
            <td>{sb.natuur}</td>
            <td>{sb.hemellichaam.naam}</td>
            <td>{sb.hemellichaam.afkorting}</td>
            <td>{sb.hemellichaam.symbool}</td>
            <td>{sb.hemellichaam.kenmerk}</td>
            <td>{sb.kruis}</td>
            <td>{sb.element}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </Page>
  );
}
